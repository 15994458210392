<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <v-progress-circular indeterminate size="32" width="2" color="grey darken-2"/>
                </v-row>
            </template>
            <template v-if="isViewReady && profileRequest">
                <!-- TODO: isPartnerBrandModeSidebar should show a left side card with partner brandprofile and then right-side card with the request info -->
                <template v-if="isPartner && partnerBrandprofile && isPartnerBrandModeProminent">
                    <BrandImage :alias="partnerBrandprofile" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
                </template>
                <template v-if="brandprofile">
                    <BrandImage :alias="brandprofile" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
                    <!-- <p class="text-center">{{ profileRequest.brandprofile }}</p> -->
                </template>
                <template v-if="profileRequest.domain">
                    <p class="text-center">{{ profileRequest.domain }}</p>
                </template>
                <template v-if="!isError">
                    <!-- TODO: we  need to build what is mising here, which is an API for these subcomponents where we treat them as a group and only one is editable at a time ... so they need a readonly mode , with an "editable" attribute, and an event for when user tries to edit them, so we can make all the other ones readonly/disabled while that one is being edited, and an event for when user finishes editing (new input) or cancels editing (so we can enable edit buttons everywhere if nothing is being edited) -->
                    <BrandCard>
                        <template #title>
                            <span v-if="isFormComplete">Confirm</span>
                            <span v-if="!isFormComplete">{{ title }}</span>
                        </template>
                        <!-- <v-toolbar dense flat color="blue darken-2" dark>
                            <v-toolbar-title v-if="!isFormComplete">Register</v-toolbar-title>
                            <v-toolbar-title v-if="isFormComplete">Confirm</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar> -->
                        <!-- <v-form @submit.prevent="submit" onSubmit="return false;" @keyup.enter.native.prevent="submit" v-if="profileRequestItems" class="mx-4 pb-6"> -->
                            <div v-for="(item, idx) in profileRequestItems" :key="idx">
                                <component :is="item.component" :attr="item.attr" :value="item.value" :required="item.required" :readonly="item.readonly" :idx="idx" :active="idx === activeItemIdx" :verification="item.verification" @input="onInput" @activate="onActivate"></component>
                            </div>
                            <!-- TODO: anything besdies email, we can show what will be needed but user needs to authenticate if theyr'e not already signed in; show sign up or sign in link fo rthat -->
                            <!-- TODO: when form is not complete, if email changed or not in profile yet the continue button should be to submti the email to server, and then server will respond if it needs email verification or not... -->
                            <v-row no-gutters justify="center" v-if="isFormComplete && activeItemIdx === null">
                                <v-btn @click="shareProfileResponse" elevation="4" :style="primaryButtonStyle" class="mb-4">Continue</v-btn> <!--  color="blue darken-2 white--text" -->
                            </v-row>
                        <!-- </v-form> -->
                    </BrandCard>
                </template>
                <template v-if="isPartner && partnerBrandprofile && isPartnerBrandModePoweredBy">
                    <p class="text-center mt-8">Powered by</p>
                    <BrandImage :alias="partnerBrandprofile" intent="logotype" mode="light" etag="0" :height="56" :max-height="56" contain style="margin-left: auto; margin-right: auto;" class="mb-6"/>
                </template>
                <!-- TODO: only show a sign up link if the profile request invovles more than just email/captcha ... and it should be phrased differently, something more like "we also need to verify your phone number / etc.  and for that you need to 1) sign up, 2) get the EtherLink app for deskto/mobile, 3) use the app to verify your phone number / etc." -->
                <!-- <template v-if="!checkEmail && !isCheckLoginPending">
                    <p class="mt-6 grey--text text--darken-2 text-center">No profile yet? <router-link :to="{ name: 'signup' }">Sign up</router-link></p>
                </template> -->
            </template>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Go back and try again.
            </v-alert>
            <v-alert type="error" v-if="promptError">
                The link is expired or invalid. Go back and try again.
            </v-alert>
            <v-alert type="error" v-if="unauthorizedError">
                You must sign in to complete this profile request. <!-- TODO: show a message about sign in or sign up with links to appropriate pages that will redirect back here when complete -->
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-form p {
    font-size: 0.9em;
    color: #707070;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState, mapGetters } from 'vuex';
import { isEmpty } from '@/sdk/input';
import BrandCard from '@/components/BrandCard.vue';
import BrandImage from '@/components/BrandImage.vue';
import PromptEmail from '@/components/prompt/PromptEmail.vue';
// import { loginshieldInit } from '@loginshield/realm-client-browser';
// import { client } from '@/client';
// function loginshieldInit() {
//     return null; // TODO: we should make a loginfront sdk with iframe like loginshield, so it can be used without redirecting user to another site
// }

// TODO: use loginfront sdk to login, instead of having it built in here

export default {
    components: {
        BrandCard,
        BrandImage,
        PromptEmail,
    },

    data: () => ({
        isViewReady: false,
        profileRequest: null,
        profileRequestItems: null,
        activeItemIdx: null,
        isCheckLoginPending: false,
        interactionId: null,
        username: '',
        submitTimestamp: null,
        checkEmail: false,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        unauthorizedError: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        promptError: false,
        expiredError: false,
        isRememberMeChecked: null,
    }),

    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
            brand: (state) => state.brand,
            brandprofile: (state) => state.brandprofile,
            partnerBrandprofile: (state) => state.partnerBrandprofile,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            isAuthenticatedReady: 'isAuthenticatedReady',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isError() {
            return this.serverError || this.requestError || this.inputError || this.forbiddenError || this.promptError || this.unauthorizedError;
        },
        // isEmailRequired() {
        //     return this.profileRequest?.required.includes('email') && !this.profileRequest?.profile_response?.email;
        // },
        isRequiredFormComplete() {
            return this.profileRequest?.required_remaining?.length === 0;
        },
        isOptionalFormComplete() {
            return this.profileRequest?.optional_remaining?.length === 0;
        },
        isFormComplete() {
            return this.isRequiredFormComplete && this.isOptionalFormComplete;
        },
        isPartner() {
            return this.profileRequest?.is_partner;
        },
        isPartnerBrandModeProminent() {
            return this.profileRequest?.partner_brand_mode === 'prominent'; // TODO: should be a programming constant
        },
        isPartnerBrandModeSidebar() {
            return this.profileRequest?.partner_brand_mode === 'sidebar'; // TODO: should be a programming constant
        },
        isPartnerBrandModePoweredBy() {
            return this.profileRequest?.partner_brand_mode === 'powered_by'; // TODO: should be a programming constant
        },
        isPartnerBrandModeNone() {
            return this.profileRequest?.partner_brand_mode === 'none'; // TODO: should be a programming constant
        },
        title() {
            let title;
            if (Array.isArray(this.profileRequestItems) && Number.isInteger(this.activeItemIdx)) {
                const item = this.profileRequestItems[this.activeItemIdx];
                switch (item.attr) {
                case 'email':
                    title = 'Email';
                    break;
                default:
                    title = item.attr;
                    // TODO: send API call to server to notify that unknown attribute name ${item.attr} is present, so development team can add the correct title here
                    break;
                }
            } else {
                title = 'Confirm';
            }
            return title;
        },
    },

    watch: {
        focus() {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
        isRememberMeChecked(value) {
            console.log(`Login.vue: isRememberMeChecked watcher: storing ${value}`);
            localStorage.setItem('rememberMe', value);
        },
    },

    methods: {
        reset() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.unauthorizedError = false;
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.checkEmail = false;
            this.verificationExpires = null;
        },
        componentName(attrKey) {
            let result;
            switch (attrKey) {
            case 'email':
                result = 'PromptEmail';
                break;
            default:
                result = null;
            }
            return result;
        },
        async loadBrand(brandprofile) {
            this.$store.commit('brandprofile', brandprofile);
            // intentionally not using 'await' bfor loadBrand and loadPalette so they will load concurrently
            this.$store.dispatch('loadBrand', { alias: brandprofile, mode: 'light', media_type: 'screen' });
            this.$store.dispatch('loadPalette', { alias: brandprofile, mode: 'light', media_type: 'screen' });
        },
        async loadPartnerBrand(partnerBrandprofile) {
            this.$store.commit('partnerBrandprofile', partnerBrandprofile);
            // intentionally not using 'await' bfor loadBrand and loadPalette so they will load concurrently
            this.$store.dispatch('loadPartnerBrand', { alias: partnerBrandprofile, mode: 'light', media_type: 'screen' });
            this.$store.dispatch('loadPartnerPalette', { alias: partnerBrandprofile, mode: 'light', media_type: 'screen' });
        },
        async startProfileResponse(token) {
            console.log(`startProfileResponse ${token}`);
            try {
                this.$store.commit('loading', { startProfileResponse: true });
                const response = await this.$client.main().profileResponse.create({ token });
                if (response) {
                    const { interactionId } = response;
                    this.interactionId = interactionId;
                    this.$router.replace({ name: 'prompt', query: { i: interactionId } });
                    console.log(`startProfileResponse replacing query with interactionId ${interactionId}`);
                    this.loadProfileResponseForm(interactionId);
                } else {
                    this.promptError = true;
                }
            } catch (err) {
                console.error('startProfileResponse failed', err);
                if (err.response?.status) {
                    if (err.response.status === 400) {
                        this.promptError = true;
                    } else if (err.response.status === 403) {
                        this.forbiddenError = true;
                    } else if (err.response.status === 401) {
                        this.unauthorizedError = true;
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                    } else {
                        console.error(`startProfileResponse failed with unexpected http status code: ${JSON.stringify(err.response.status)}`, err);
                        this.promptError = true;
                    }
                } else {
                    console.error('startProfileResponse failed', err);
                    this.promptError = true;
                }
            } finally {
                this.$store.commit('loading', { startProfileResponse: false });
            }
        },
        async editProfileResponse(request) {
            try {
                this.$store.commit('loading', { editProfileResponse: true });
                const response = await this.$client.main().profileResponse.edit(this.interactionId, request);
                if (response) {
                    this.prepareView(response);
                } else {
                    this.promptError = true;
                }
            } catch (err) {
                if (err.response?.status) {
                    console.error('editProfileResponse failed request', err);
                    if (err.response.status === 400) {
                        this.promptError = true;
                    } else if (err.response.status === 403) {
                        this.forbiddenError = true;
                    } else if (err.response.status === 401) {
                        this.unauthorizedError = true;
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                    } else {
                        console.error(`shareProfileResponse failed with unexpected http status code: ${JSON.stringify(err.response.status)}`, err);
                        this.promptError = true;
                    }
                } else {
                    console.error('shareProfileResponse failed', err);
                    this.promptError = true;
                }
            } finally {
                this.$store.commit('loading', { editProfileResponse: false });
            }
        },
        async shareProfileResponse() {
            try {
                if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitTimestamp = Date.now();
                this.$store.commit('loading', { shareProfileResponse: true });
                // TODO: should we check this.isFormComplete first ??
                const response = await this.$client.main().profileResponse.share(this.interactionId);
                const { redirect } = response;
                if (redirect) {
                    // show a link after 2 seconds in case auto-redirect fails
                    console.log(`shareProfileResponse redirect: ${redirect}`);
                    setTimeout(() => {
                        this.redirect = redirect;
                    }, 2000);
                    // attempt auto-redirect
                    if (typeof window.location.push === 'function') {
                        window.location.push(redirect);
                    } else {
                        window.location.href = redirect;
                    }
                }
            } catch (err) {
                if (err.response?.status) {
                    console.error('shareProfileResponse failed request', err);
                    if (err.response.status === 400) {
                        this.promptError = true;
                    } else if (err.response.status === 403) {
                        this.forbiddenError = true;
                    } else if (err.response.status === 401) {
                        this.unauthorizedError = true;
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                    } else {
                        console.error(`shareProfileResponse failed with unexpected http status code: ${JSON.stringify(err.response.status)}`, err);
                        this.promptError = true;
                    }
                } else {
                    console.error('shareProfileResponse failed', err);
                    this.promptError = true;
                }
            } finally {
                this.$store.commit('loading', { shareProfileResponse: false });
            }
        },
        async loadProfileResponseForm(interactionId) {
            try {
                this.$store.commit('loading', { loadProfileResponseForm: true });
                const response = await this.$client.main().profileResponse.get(interactionId);
                console.log(`loadProfileResponseForm response: ${JSON.stringify(response)}`);
                this.interactionId = interactionId;
                this.prepareView(response);
            } catch (err) {
                console.error('failed to load interaction', err);
                if (err.response?.status) {
                    if (err.response.status === 400) {
                        this.promptError = true;
                    } else if (err.response.status === 403) {
                        this.forbiddenError = true;
                    } else if (err.response.status === 401) {
                        this.unauthorizedError = true;
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                    } else {
                        console.error(`startProfileResponse failed with unexpected http status code: ${JSON.stringify(err.response.status)}`, err);
                        this.promptError = true;
                    }
                } else {
                    console.error('startProfileResponse failed', err);
                    this.promptError = true;
                }
            } finally {
                this.$store.commit('loading', { loadProfileResponseForm: false });
            }
        },
        /**
         * Precondition: profileRequest and profileResponse are set
         */
        async prepareView(response) {
            console.log(`prepareView for response: ${JSON.stringify(response)}`);
            this.profileRequest = response; // { required, optional, input, readonly, domain, brandprofile, duration, authenticated, profile_response, status }

            // TODO: also check with vuex store... if we already loaded the brand and it hasn't changed (from another activity) no need to do it again...
            if (this.profileRequest.brandprofile && this.profileRequest.brandprofile !== this.brandprofile) {
                this.loadBrand(this.profileRequest.brandprofile);
            }
            if (this.profileRequest.is_partner && this.profileRequest.realm_brandprofile && this.profileRequest.realm_brandprofile !== this.partnerBrandprofile) {
                this.loadPartnerBrand(this.profileRequest.realm_brandprofile);
            }

            if (this.profileRequest.duration === 0) {
                console.error('prepareView: request expired');
                // TODO: if duration is zero, it means the interaction expired and we need to show a message with a link to to the expired/cancelled URL provided by client application
                this.expiredError = true;
                return;
            }

            /*
            // TODO: this needs rework with the new roadmap; signup/login currently not being used
            if (this.profileRequest.status === 'login_required') {
                const searchParams = new URLSearchParams();
                searchParams.set('i', this.interactionId);
                const query = searchParams.toString();
                console.log(`login required: query ${query}`);
                this.$router.push({ name: 'login', query: { next: `/prompt?${query}` } });
                return;
            }
            */

            if (!['new', 'draft', 'ready'].includes(this.profileRequest.status)) {
                console.error(`prepareView: unexpected profile response status: ${JSON.stringify(this.profileRequest.status)}`);
                this.promptError = true;
                return;
            }

            const {
                required = [],
                optional = [],
                input = {},
                readonly = [],
                verification = {},
                profile_response: profileResponse = {},
                // domain,
                // brandprofile,
                // duration,
                // partial_required,
                // partial_optional,
                // authenticated,
            } = this.profileRequest;

            const all = [...required, ...optional]; // assume the server provides them in the desired order
            const items = all.map((attrKey) => {
                const item = {};
                item.attr = attrKey;
                item.required = required?.includes(attrKey) ?? false;
                item.value = profileResponse[attrKey] ?? input[attrKey];
                item.readonly = readonly?.includes(attrKey) ?? false;
                item.component = this.componentName(attrKey);
                item.verification = verification[attrKey] ?? null; // should only be present when verification is in progress for this item
                item.complete = !isEmpty(profileResponse[attrKey]);
                return item;
            });
            this.profileRequestItems = items;
            if (['new', 'draft'].includes(this.profileRequest.status)) {
                let activeItemIdx = null;
                for (let i = 0; i < items.length; i += 1) {
                    if (!items[i].complete && !items[i].verification) {
                        activeItemIdx = i;
                        break;
                    }
                }
                this.activeItemIdx = activeItemIdx;
            }

            // When request is ready to share (required_remaining.length === 0), we show a confirmation dialog with "continue" button.
            // However, if the request is configured as a "quick" request, and there are no optional fields remaining either,
            // we skip the confirmation and continue back to website immediately.
            // If there are optional fields we stay here to give the user an opportunity to complete them.
            if (this.profileRequest.status === 'ready') {
                this.activeItemIdx = null; // all items should be in display (non-editing) mode so user can review it all and decide to share, or edit one of the items, or cancel
                if (this.profileRequest.is_quick) {
                    console.log('prepareView: status is ready and request marked is_quick');
                    await this.shareProfileResponse();
                }
                this.isViewReady = true;
                return;
            }

            // If the request is marked as "quick" to streamline some steps, AND
            // if the request requires email verification, AND
            // if we have an input for the email (either from user or pre-filled from website), AND
            // if the user hasn't yet verified or selected an email to include in the response, AND
            // if an email verification isn't already in progress, THEN we can auto-submit the
            // email address to send the verification email immediately.
            if (this.profileRequest.is_quick && required.includes('email') && input.email && !profileResponse.email && !verification.email) {
                const editResponse = await this.$client.main().profileResponse.edit(this.interactionId, { email: input.email });
                this.prepareView(editResponse); // response from edit profile response API has same format as response from fetch profile response form API
            }
            this.isViewReady = true;
        },
        async submit() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                // TODO: if changed email address, do an edit for that;  if all done and no changes, confirm.  maybe it should be separate buttons and separate functions?
                console.log('prompt.vue submit');
                /*
                this.reset();
                if (typeof this.username !== 'string' || this.username.trim().length === 0 || !isValidEmail(this.username)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signup: true });
                const request = {
                    email: this.username,
                    interactionId: this.interactionId, // null on first request, value after email verification
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.startLogin(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'check_email':
                        // email verification is required; user should check inbox
                        this.checkEmail = true;
                        if (Number.isInteger(response.verification_max_age)) {
                            this.verificationExpires = toText(response.verification_max_age);
                        } else {
                            this.verificationExpires = null;
                        }
                        break;
                    case 'signup_required':
                        // email address was verified but user is not registered
                        this.$router.push({ name: 'signup', query: { username: this.username } });
                        break;
                    case 'authenticated':
                        // user is already authenticated with same email address
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'setup_required':
                        // user is already authenticated with same email address, but hasn't set up
                        // loginshield yet
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        await this.$store.dispatch('loadSession');
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'redirect':
                        // server started a login request and we need to redirect to this URL to continue
                        // use replace so that when user taps 'back' button from there, they won't
                        // end up being redirected again to where they just wanted to come back from
                        if (typeof window.location.replace === 'function') {
                            window.location.replace(response.redirect);
                        } else {
                            // TODO: also show link for user to click
                            window.location.href = response.redirect;
                        }
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`submit error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
                */
            } catch (err) {
                console.error('failed to edit profile response', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.reset();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { signup: false });
            }
        },
        // onSelectBrand({ alias }) {
        //     if (this.$route.query.intent === 'unsubscribe') {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias }, hash: '#unsubscribe' });
        //     } else {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias } });
        //     }
        // },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        switchAccount() {
            this.reset();
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
        async checkLogin() {
            try {
                this.$store.commit('loading', { checkLogin: true });
                this.isCheckLoginPending = true;
                if (this.$route.query.loginfront) {
                    const { isAuthenticated } = await this.$client.main().authn.checkLogin({ token: this.$route.query.loginfront });
                    if (isAuthenticated) {
                        await this.$store.dispatch('loadSession');
                        await this.$store.dispatch('loadUser');
                        this.redirectAfterLogin();
                        return;
                    }
                    this.$store.commit('session', { isAuthenticated: false });
                    this.$store.commit('user', {});
                } else {
                    // shouldn't get here without a token, but if we do then reset the form completely
                    this.reset();
                    this.username = null;
                    this.interactionId = null;
                    this.isCheckLoginPending = false;
                }
            } catch (err) {
                console.error('start login failed', err);
                this.loginshieldStartError = true;
            } finally {
                this.$store.commit('loading', { checkLogin: false });
            }
        },
        resetErrors() {
            this.passwordError = false;
            this.loginshieldStartError = false;
        },
        resetLoginForm() {
            this.loginWithLoginShield = false;
            this.loginPasswordInput = false;
            this.loginUsernameInput = true;
            this.username = '';
            this.password = '';
            this.$refs.usernameField.reset();
            this.$refs.passwordField.reset();
            this.$nextTick(() => this.$refs.usernameField.focus());
        },
        onInput(item) {
            console.log(`oninput: ${JSON.stringify(item)}`);
            switch (item.attr) {
            case 'email':
                this.onInputEmail(item.value);
                break;
            default:
                console.error(`unsupported input attr: ${item.attr}`);
                break;
            }
        },
        async onInputEmail(value) {
            this.editProfileResponse({ email: value });
            /*
            try {
                this.$store.commit('loading', { verifyEmail: true });
                const request = {
                    email: value,
                    interactionId: this.interactionId, // null on first request, value after email verification
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'check_email':
                        // email verification is required; user should check inbox
                        this.checkEmail = true;
                        if (Number.isInteger(response.verification_max_age)) {
                            this.verificationExpires = toText(response.verification_max_age);
                        } else {
                            this.verificationExpires = null;
                        }
                        break;
                    case 'setup_required':
                        // user record was created; next step is get the app or set up LoginShield
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        await this.$store.dispatch('loadSession');
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'authenticated':
                        // user is already authenticated with same email address
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'login_required':
                        // email is verified but user not authenticated here; redirect to login
                        this.$router.push({ name: 'login' });
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {

            }
            */
        },
        onActivate(item) {
            console.log(`onActivate: ${JSON.stringify(item)}`); // item like { attr, idx } for example { attr: "email", idx: 0 }
            this.activeItemIdx = item.idx;
            // temporarily hide the verification info for this item; it will be restored when user submits the form with a new value
            const profileRequestItem = this.profileRequestItems[item.idx];
            profileRequestItem.verification = null;
            this.$set(this.profileRequestItems, item.idx, profileRequestItem);
        },
    },

    mounted() {
        /**
         * The first time the user visits this page, the user should arrive with a profile_request token.
         * We call the create profile response API with this token.
         *
         * If the service responds with 401 unauthorized, we
         * need to redirect the user to sign in.
         *
         * If the service responds with an interactionId and form information, we need to replace the
         * current URL to show the interaction ID instead of the token so that if the user reloads
         * the page the interaction id will not be lost. All subsequent
         * requests to edit the profile response MUST include this interaction id.
         */

        if (this.$route.query.i) {
            this.loadProfileResponseForm(this.$route.query.i);
            return;
        }

        if (this.$route.query.token) {
            this.startProfileResponse(this.$route.query.token);
            return;
        }

        this.promptError = true;

        /*
        // if another view redirected here with the intent of returning, it has to provide
        // the path and optional query parameters to redirect the user after login; the path
        // MUST be a relative path because this is not the right place for unrelated logic
        // about where we can redirect the user; the 'next' route could then redirect the
        // user to an offsite URL if needed
        const { next } = this.$route.query;
        if (typeof next === 'string' && next.startsWith('/')) {
            this.next = next;
        }

        const rememberMeStored = localStorage.getItem('rememberMe');
        this.isRememberMeChecked = rememberMeStored === 'true';

        // this.$nextTick(() => {
        //     setTimeout(() => { this.activate('usernameInput'); }, 1);
        // });
        */

        // TODO: load brand info if we have this.$route.query.brand  (is probably etherlink brand for signup)
        // if (this.username) {
        //     this.search();
        // }
    },
};
</script>
