<template>
    <v-card flat tile>
        <!-- "active" means the user is editing the email address to submit, only happens when input is editable according to the request and this is the first prompt for it or user clicked the edit button; "inactive" means the input email address is displayed and it may or may not be editable, depending on the request settings -->
        <template v-if="active">
            <v-form @submit.prevent="submit" onSubmit="return false;" @keyup.enter.native.prevent="submit" class="mx-4 pb-6">
            <v-card-text class="px-0">
                <p class="mb-0" v-if="readonly">We need to verify your email address:</p>
                <p class="mb-0" v-if="!readonly">What is your email address?</p>
            </v-card-text>
            <v-text-field v-model="email" ref="emailInput" dense solo :color="primaryColor" placeholder="Email" :error-messages="inputError" :readonly="readonly">
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                </template>
            </v-text-field>
            <v-row no-gutters justify="center" v-if="!readonly">
                <v-btn @click="submit" elevation="2" :style="primaryButtonStyle">Continue</v-btn>
            </v-row>
            </v-form>
        </template>
        <template v-if="!active">
            <!--
            <v-text-field v-model="email" dense solo flat :color="primaryColor" placeholder="Email" :error-messages="inputError" readonly>
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                </template>
                <template #append-outer v-if="!readonly">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon :color="primaryColor" @click="edit()" v-on="on">
                                <span :color="primaryColor">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" size="1x"/>
                                </span>
                            </v-btn>
                        </template>
                        <span>Edit email address</span>
                    </v-tooltip>
                </template>
            </v-text-field>
            -->
            <v-card-text>
                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                {{ email }}
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn icon :color="primaryColor" @click="edit()" v-on="on">
                            <span :color="primaryColor">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" size="1x"/>
                            </span>
                        </v-btn>
                    </template>
                    <span>Edit email address</span>
                </v-tooltip>
            </v-card-text>
        </template>
        <template v-if="verification">
            <v-card-text>
                <!-- TODO: get email subject line from 'status' object, so it matches exactly what was sent; for example it may have the brandprofile in there instead of "Cryptium ID"; maybe show the entire email as a preview: we can show it here in a table like we're showing an email, with "from", "subject", "to" and put the pencil icon next to the "to" value if the user wants to edit,  instead of a readonly form field.   we can even show a message body area with a label "look for this message in your inbox or spam folder".  -->
                <p>Look for an email with the subject <b>{{ verification.subject }}</b>.</p>
                <!-- TODO: commented out the preview below because it didn't look great and also don't want to confuse anybody. The subject line enough (and more than many places provide) and if we can make the preview look better maybe we can bring it back. -->
                 <!-- #283593 is indigo darken-3 -->
                <!-- <table>
                    <tbody>
                    <tr>
                        <td colspan="2" style="text-align: start; background-color: #283593" class="px-2">
                            <font-awesome-icon :icon="['fas', 'envelope']" color="white" fixed-width size="1x"></font-awesome-icon>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: start;" class="px-2">To:</th>
                        <td style="text-align: start;" class="px-2">{{ verification.to }}</td>
                    </tr>
                    <tr>
                        <th style="text-align: start;" class="px-2">From:</th>
                        <td style="text-align: start;" class="px-2">{{ verification.from }}</td>
                    </tr>
                    <tr>
                        <th style="text-align: start;" class="px-2">Subject:</th>
                        <td style="text-align: start;" class="px-2"><b>{{ verification.subject }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: start;" class="px-2" v-html="verificationText"></td>
                    </tr>
                    </tbody>
                </table> -->
            </v-card-text>
        </template>
    </v-card>
</template>

<style scoped>
table, th, td {
    border: 1px solid black;
}
table {
    border-collapse: collapse;
}
</style>
<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState, mapGetters } from 'vuex';
import { isValidEmail } from '@/sdk/input';

export default {
    props: [
        'attr', // 'email', 'phone', etc.
        'value', // the phone number, email address, etc.
        'required', // boolean
        'readonly', // boolean
        'idx', // non-negative integer indicating the attribute's order in the list of attributes to verify
        'verification', // object with current verification in progress (this can be null if no verification is in progress); for example an email verification would have the email preview here so we can show user what to look for
        'active', // boolean
        'errors', // array of string
    ],
    data: () => ({
        email: null,
        checkEmail: false,
        inputError: null,
        inputErrorTimeout: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            // session: (state) => state.session,
            focus: (state) => state.focus,
            brand: (state) => state.brand,
        }),
        ...mapGetters({
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        verificationText() {
            if (!this.verification || !this.verification.text) {
                return null;
            }
            return this.verification.text.replaceAll('\n', '<br>'); // .replace('<br>#<br>', '<br>[The link will be shown here]<br>');
        },
    },
    watch: {
        value(newValue) {
            this.email = newValue;
        },
        active(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('emailInput'); }, 1);
                });
            }
        },
    },
    methods: {
        reset() {
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.checkEmail = false;
        },
        /*
        initView() {
            if (Array.isArray(this.value)) {
                this.editableTiers = this.value;
            } else {
                this.editableTiers = [];
            }
            if (this.editableTiers.length > 1) {
                this.editableTiers.splice(1); // flat price requires a single tier, so delete all tiers after first one
            }
            if (this.editableTiers.length > 0) {
                const tier = this.editableTiers[0];
                const flatPriceExp = Number.isInteger(tier.flat_price_exp) ? tier.flat_price_exp : -2;
                const flatPrice = Number.isInteger(tier.flat_price) ? tier.flat_price : null;
                const flatPriceDecimal = Number.isInteger(flatPrice) ? flatPrice * (10 ** flatPriceExp) : null;
                this.editableFlatPrice = flatPriceDecimal?.toString(10); // may be null
            } else {
                this.editableFlatPrice = null;
            }
            this.$nextTick(() => {
                setTimeout(() => { this.activate('editableFlatPriceInput'); }, 1);
            });
        },
        */
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        edit() {
            this.$emit('activate', { attr: this.attr, idx: this.idx });
        },
        /*
        editMode() {
            this.reset();
            this.$nextTick(() => {
                setTimeout(() => { this.activate('emailInput'); }, 1);
            });
        },
        */
        async submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                if (!isValidEmail(this.email)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$emit('input', { attr: this.attr, idx: this.idx, value: this.email });
                /*
                this.$store.commit('loading', { verifyEmail: true });
                const request = {
                    email: this.email,
                    interactionId: this.interactionId, // null on first request, value after email verification
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'check_email':
                        // email verification is required; user should check inbox
                        this.checkEmail = true;
                        if (Number.isInteger(response.verification_max_age)) {
                            this.verificationExpires = toText(response.verification_max_age);
                        } else {
                            this.verificationExpires = null;
                        }
                        break;
                    case 'setup_required':
                        // user record was created; next step is get the app or set up LoginShield
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        await this.$store.dispatch('loadSession');
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'authenticated':
                        // user is already authenticated with same email address
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'login_required':
                        // email is verified but user not authenticated here; redirect to login
                        this.$router.push({ name: 'login' });
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
                */
            } catch (err) {
                console.error('failed to request email verification', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.reset();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { verifyEmail: false });
            }
        },
        // cancel() {
        //     this.editMode = false;
        //     this.editableFlatPrice = null;
        //     this.editableTiers = null;
        // },
    },
    mounted() {
        // this.initView();
        if (this.value) {
            this.email = this.value;
        }
        if (this.active) {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('emailInput'); }, 1);
            });
        }
    },
};
</script>
